import React from "react";
import Layout from "./layout";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";

export const wrapPageElement = ({ element }) => {
  const theme = extendTheme({
    styles: { global: { img: { maxWidth: "unset" } } },


    colors: {
      gray: {
        50: "#f7fafc",
        100: "#edf2f7",
        200: "#e2e8f0",
        300: "#cbd5e0",
        400: "#a0aec0",
        500: "#718096",
        600: "#4a5568",
        700: "#2c3748",
        800: "#1a202c",
        900: "#171923",
      },
      red2: {
        50: "#fbeaea",
        100: "#f2c0bf",
        200: "#e99795",
        300: "#e16d6b",
        400: "#d84340",
        500: "#d42e2b",
        600: "#bf2a27",
        700: "#94201e",
        800: "#6a1716",
        900: "#400e0d",
      },
      offwhite: {
        50: "#f2f2f2",
        100: "#d9d9d9",
        200: "#bfbfbf",
        300: "#a6a6a6",
        400: "#8c8c8c",
        500: "#808080",
        600: "#737373",
        700: "#595959",
        800: "#404040",
        900: "#262626",
      },
      brownblack: {
        50: "#f3f2f2",
        100: "#dad7d9",
        200: "#c2bcc0",
        300: "#aaa2a7",
        400: "#91878e",
        500: "#857a81",
        600: "#786e74",
        700: "#5d555b",
        800: "#433d41",
        900: "#282527",
      },
    },
    fonts: { heading: 'Source Sans Pro', body:'Source Sans Pro' },
  });

  return (
    <ChakraProvider theme={theme}>
      <Layout>{element}</Layout>
    </ChakraProvider>
  );
};
